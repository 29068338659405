














import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import Billing from '../../components/org/Billing.vue';
@Component({
  components: {
    Billing,
  },
})
export default class BillingClass extends BaseVue {
  public selectedTab = 0;
}
